var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"contenedor_chats"},[_c('v-row',{staticClass:"my-4"},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateQuery,"transition":"scale-transition","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateQuery=$event},"update:return-value":function($event){_vm.dateQuery=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('Tickets.menu.label'),"prepend-inner-icon":"event","outlined":"","dense":"","hide-details":"","readonly":"","clearable":""},model:{value:(_vm.dateQuery),callback:function ($$v) {_vm.dateQuery=$$v},expression:"dateQuery"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},on:{"change":_vm.saveDate},model:{value:(_vm.dateQuery),callback:function ($$v) {_vm.dateQuery=$$v},expression:"dateQuery"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.estatusSelection,"label":_vm.$t('Tickets.label'),"dense":"","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.estatus),callback:function ($$v) {_vm.estatus=$$v},expression:"estatus"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","hide-details":"","prepend-inner-icon":"search","placeholder":_vm.$t('Tickets.placeholder'),"clearable":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('v-dialog',{staticStyle:{"overflow":"hidden"},attrs:{"scrollable":false,"max-width":600},model:{value:(_vm.isChatOpen),callback:function ($$v) {_vm.isChatOpen=$$v},expression:"isChatOpen"}},[(_vm.isChatOpen)?_c('div',{staticClass:"chats d-flex justify-center align-start"},[(!_vm.chats[0] && !_vm.offChats)?_c('v-progress-circular',{staticClass:"mt-5",attrs:{"size":60,"width":"6","indeterminate":"","color":"primary"}}):(_vm.offChats)?_c('div',{staticClass:"d-flex flex-column align-start mt-5"},[_vm._v(" "+_vm._s(_vm.$t('Tickets.noChat.text'))+" ")]):_c('div',{staticClass:"d-flex flex-column align-start"},_vm._l((_vm.chats),function(chat){return _c('div',{key:chat.id,staticClass:"chat_bubble",class:chat.userId === 'system'
                ? 'center_bubble'
                : chat.userType === 'codeless'
                ? 'right_bubble'
                : ''},[_c('p',[_vm._v(_vm._s(chat.contents))]),_c('span',[_vm._v(_vm._s(chat.timestamp))])])}),0)],1):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.conversartionFiltered,"loading":_vm.isLoading,"loading-text":_vm.$t('Tickets.table.loading-text'),"show-select":false},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleChat(item._id)}}},[_c('v-icon',[_vm._v("visibility")])],1)]}},{key:"item.status",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.status === 'closed' ? _vm.$t('Tickets.statuses.closed') : _vm.$t('Tickets.statuses.open'))+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noDataText)+" ")]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }